import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Search from "../components/search";
import { Link } from "gatsby";

const Paragraph = ({ children }) => {
  return (
    <p className="text-gray-600 mt-2" style={{ textIndent: "3em" }}>
      {children}
    </p>
  );
};

const AboutPage = () => {
  return (
    <Layout>
      <Seo title="เกี่ยวกับเรา" />
      <div className="container mx-auto px-4 max-w-screen-sm">
        <section className="mt-4">
          <Search />
        </section>
        <section className="my-8">
          <h1 className="text-gray-700 text-xl">เกี่ยวกับเรา</h1>
          <Paragraph>
            กองแม่.com เป็นโปรเจกต์หลังเลิกงานของแอดมิน
            เกิดขึ้นจากความต้องการของแอดมินเอง ที่ศึกษาการลงทุนในกองทุนรวม
            ซึ่งในปัจจุบันมีกองทุนรวมมากมาย มีการลงทุนในต่างประเทศด้วย
            กองทุนเหล่านี้มักจะเป็นการลงทุนในกองทุนที่ต่างประเทศอีกที
            (ที่เรียกติดปากกันว่า "กองแม่")
            การศึกษาข้อมูลจากแหล่งข้อมูลต่างประเทศโดยตรง
            จึงเป็นส่วนสำคัญส่วนหนึ่งที่ช่วยในการตัดสินใจเลือกกองทุน และ
            ช่วยในการติดตามข้อมูลต่างได้ดียิ่งขึ้น นอกจากนี้
            ผู้ลงทุนยังสามารถติดตามราคาคร่าวๆได้รวดเร็วขึ้น โดยไม่ต้องรอ บลจ.
            ไทยคำนวณราคา ที่มักจะเป็นราคาของ 2 วันที่แล้ว
          </Paragraph>
          <Paragraph>
            หากค้นหากองทุนที่แอดมินรวบรวมข้อมูลแล้ว
            จะมีชื่อของกองทุนหลักที่กองทุนนั้นลงทุน พร้อมทั้ง
            ลิงค์ไปยังแหล่งข้อมูลต่างๆที่สามารถไปอ่านดูได้
            มีเว็บไซต์ของกองทุนหลัก และนอกจากนี้ยังมี
            เว็บไซต์ที่เป็นแหล่งข้อมูลอื่นๆอีกด้วย เช่น Bloomberg และ
            Morningstar
          </Paragraph>
          <Paragraph>
            สำหรับกองทุนที่ กองแม่.com ยังไม่ได้มีการรวบรวมข้อมูล
            หากมีข้อมูลกองทุนหลักจาก กลต. กองแม่.com ก็จะแสดงข้อมูลนั้นแทน
            โดยแอดมันจะพยายามรวบรวมข้อมูลกองทุนต่างๆมาเพิ่มโดยเร็วที่สุดครับ
          </Paragraph>
          <Paragraph>
            แอดมินหวังเป็นอย่างยิ่งว่าข้อมูลใน กองแม่.com
            จะเป็นประโยชน์สำหรับนักลงทันทุกท่าน หากมีคำแนะนำติชม สามารถ
            <Link to="/contact" className="underline">
              ติดต่อ
            </Link>
            แอดมินมาได้เลยครับ
          </Paragraph>
        </section>
      </div>
    </Layout>
  );
};

export default AboutPage;
